'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaDispatching.factory:DispatchGroups

 # @description

###
angular
  .module 'lpaDispatching'
  .factory 'DispatchGroups', [
    'Restangular'
    (Restangular) ->
      Restangular.service('lpa/dispatch/groups')
  ]
